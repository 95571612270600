import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import '../components/Footer.css'
import '../components/PostCard.css'
import Image from '../components/Image'
import PostSection from '../components/PostSectionFeatured'


import PostCard from '../components/PostCard'

import CookieConsent from "react-cookie-consent";


/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = posts => {
  const now = Date.now()
  return posts.filter(post => Date.parse(post.date) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'postCategories'
  const byCategory = post =>
    post.categories &&
    post.categories.filter(cat => cat.category === title).length
  return isCategory ? posts.filter(byCategory) : posts
}


// Export Template for use in CMS preview
export const HomePageTemplate = ({
    title,
    subtitle,
    featuredImage,
    body,
    sectionAbout,
    useCases,
    newsletter,
    career,
    posts = [],
    postCategories = [],
    enableSearch = true,
    contentType
}) => (
    <Location>
      {({ location }) => {
        let filteredPosts =
          posts && !!posts.length
            ? byCategory(byDate(posts), title, contentType)
            : []

        let queryObj = location.search.replace('?', '')
        queryObj = qs.parse(queryObj)

        if (enableSearch && queryObj.s) {
          const searchTerm = queryObj.s.toLowerCase()
          filteredPosts = filteredPosts.filter(post =>
            post.frontmatter.title.toLowerCase().includes(searchTerm)
          )
      }

return (
  <main className="Home">
    <PageHeader
      large
      fronttitle={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />

    <section className="section row container">
        <div class="row space-100t container">
          <div class="column">
          <h6>About Ntention</h6>
          <h2>{sectionAbout.headline}</h2>
          </div>
        </div>
        <div class="row container">
          <div class="column">
          <p>{sectionAbout.body.left}</p>
          </div>
          <div class="column">
          <p>{sectionAbout.body.right}</p>
          </div>
        </div>
    </section>

    <section className="section row container space-100t">
          <div class="column">
            <div class="container hover-image">
            <a href={useCases.left.URL}>
              <Image src={useCases.left.imageURL} alt={useCases.left.title} className={'container hover-image full-mobile'} />
              <div class="text-block">
                <h6>{useCases.left.title}</h6>
                <h3>{useCases.left.description}</h3>
              </div>
              </a>
            </div>
          </div>
        <div class="column">
            <div class="container hover-image">
            <a href={useCases.right.URL}>
              <Image src={useCases.right.imageURL} alt={useCases.right.title} className={'container hover-image full-mobile'}/>
              <div class="text-block">
                <h6>{useCases.right.title}</h6>
                <h3>{useCases.right.description}</h3>
              </div>
              </a>
            </div>
        </div>
    </section>

    <section className="section row container">
        <div className="container space-20t space-100b">
        <h2>Recent news</h2>
          <PostSection posts={filteredPosts} />
        </div>
    </section>

    <section className="section row container">
        <div class="container">
         <div class="column">
            <div class="full-width dark">
                <h6>Newsletter</h6>
                <h2>{newsletter.title}</h2>
                <p>{newsletter.description}</p>
                <a class="button gradient" href="/subscribe">Subscribe to our newsletter</a>
            </div>
        </div>
        </div>
    </section>

    <section className="section row container">
        <div class="space-100t space-100b reverse container">
            <div class="column">
                <h6>{career.sectionTitle}</h6>
                <h2>{career.headline}</h2>
                <p>{career.body}</p>
                {/* <a class="button gradient" href="/career">Learn more</a> */}
            </div>
            <div class="column">
                <div class="full-mobile">
                  <Image src="/images/technology/Ntention-team.jpg" alt="Work with us" className={'container full-mobile'}/>
                </div>
            </div>
        </div>
    </section>


    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>
    <CookieConsent location="bottom" containerClasses="" buttonText="Continue" cookieName="CookieConsent" style={{ background: "white", boxShadow: "-10px -10px 10px rgba(0, 0, 0, .1)", color:"black", className:"container"}} buttonStyle={{ color: "white", backgroundColor: "black", fontSize: "18px", flex: "left", marginRight: "150px"}} declineButtonStyle={{ backgroundColor: "grey"}} contentStyle={{flex: "1 0 50%" }} contentClasses="cookie links" buttonClasses="cookie-button" expires={150} >
        <div className="container">
        We use cookies to improve user experience and analyze website traffic. By continuing to the site, you consent to store all the technologies described in our <a href="/cookie-policy">Cookie Policy.</a>{" "}
        </div>
    </CookieConsent>

    <PostCard />
  </main>
)
}}
  </Location>
)



// Export Default HomePage for front-end
const HomePage = ({ data: { page, posts, postCategories } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter}
    body={page.html}
    posts={posts.edges.map(post => ({
      ...post.node,
      ...post.node.frontmatter,
      ...post.node.fields
    }))}
    postCategories={postCategories.edges.map(post => ({
      ...post.node,
      ...post.node.frontmatter,
      ...post.node.fields
    }))} />
  </Layout>
)

export default HomePage





export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        sectionAbout {
            headline
            body {
                left
                right
            }
        }
        useCases {
            left {
                title
                description
                imageURL
                URL
            }
            right {
                title
                description
                imageURL
                URL
            }
        }
        newsletter {
            title
            description
        }
        career {
            sectionTitle
            headline
            body
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "postCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
